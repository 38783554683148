.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  color: #0084ff;
  margin-bottom: 30px;
}

.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.emoji {
  font-size: 24px;
  margin-right: 10px;
}

.feature p {
  font-size: 18px;
  color: #333;
}

.button {
  background-color: #0084ff;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: #0073e6;
  transform: translateY(-2px);
}

.button:active {
  transform: translateY(0);
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
}

@media (max-width: 768px) {
  .title {
    font-size: 36px;
  }

  .feature p {
    font-size: 16px;
  }

  .button {
    font-size: 16px;
    padding: 10px 20px;
  }
}