.roomContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.contentContainer {
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.view {
  overflow: auto;
}

.chatView, .documentView {
  display: flex;
  flex-direction: column;
}

/* Wide screen layout */
@media (min-width: 769px) {
  .contentContainer {
    flex-direction: row;
    max-width: 75%; /* Increased from 70% to give more space */
  }

  .view {
    display: flex !important; /* Always show both views on wide screens */
  }

  .chatView {
    flex: 4; /* This makes the chat view 3 parts of 5 */
    margin-right: 10px;
  }

  .documentView {
    flex: 2; /* This makes the document view 2 parts of 5 */
    margin-left: 10px;
  }
}

/* Mobile layout */
@media (max-width: 768px) {
  .contentContainer {
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 100%;
  }

  .view {
    display: none;
    height: calc(100vh - 120px); /* Adjust based on header and bottom nav heights */
  }

  .view.active {
    display: flex;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .contentContainer {
    margin-left: 5px;
    margin-right: 5px;
  }
}