.bottomNav {
  display: none;
}

@media (max-width: 768px) {
  .bottomNav {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .navButton {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: none;
    background-color: transparent;
    font-size: 12px;
    color: #333;
    cursor: pointer;
  }

  .navButton.active {
    color: #0084ff;
  }

  .icon {
    font-size: 24px;
    margin-bottom: 4px;
  }
}