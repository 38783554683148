.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #0084ff;
}

.users {
  display: flex;
  gap: 10px;
}

.user {
  background-color: #f0f0f0;
  padding: 6px 12px;
  border-radius: 15px;
  font-size: 12px;
  color: #333;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.username {
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.shareButton, .leaveButton {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.shareButton:hover, .leaveButton:hover {
  background-color: #e0e0e0;
}

.shareButton {
  background-color: #0084ff;
  color: white;
}

.shareButton:hover {
  background-color: #0073e6;
}

.leaveButton {
  background-color: #ff4d4d;
  color: white;
}

.leaveButton:hover {
  background-color: #ff3333;
}