.documentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.notepadBackground {
  flex: 1;
  background-image: 
    linear-gradient(#e6e6e6 1px, transparent 1px),
    linear-gradient(90deg, #fffca0 1px, transparent 1px);
  background-size: 100% 1.5em, 1px 100%;
  padding: 1.5em 1em 1em 3em;
  position: relative;
  background-color: #fffca0; /* Light yellow background */
}

.notepadBackground::before {
  content: '';
  position: absolute;
  top: 0;
  left: 2em;
  height: 100%;
  width: 1px;
  background-color: #ff9999;
}

.textArea {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.73em;
  resize: none;
  border: none;
  background-color: transparent;
  font-family: 'Arial', sans-serif;
  padding: 0;
  margin: 0;
}

.textArea:focus {
  outline: none;
}

@media (max-width: 768px) {
  .documentContainer {
    height: calc(100vh - 120px); /* Adjust based on header and bottom nav heights */
  }
}