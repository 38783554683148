:root {
  --app-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #e0e0e0; /* Light border added here */
  border-radius: 8px; /* Optional: adds rounded corners */
  background-color: #ffffff; /* Optional: ensures a white background */
}

.documentContainer {
  flex: 1; /* This will make it take up equal space */
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.messageList {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 80%;
  margin-bottom: 15px;
  padding: 12px 16px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: var(--app-font); /* Add this line */
  white-space: pre-wrap; /* Add this line */
  word-wrap: break-word; /* Add this line */
}

.userMessage {
  align-self: flex-end;
  background-color: #0084ff;
  color: white;
  text-align: left; /* Ensure text is left-aligned */
}

.aiMessage {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: #333;
  text-align: left; /* Ensure text is left-aligned */
}

.inputForm {
  display: flex;
  padding: 15px;
  background-color: #f8f8f8;
}

.input {
  flex-grow: 1;
  padding: 12px 15px;
  font-size: 14px;
  font-family: var(--app-font); /* Add this line */
  border: none;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.button {
  padding: 12px 20px;
  font-size: 14px;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: #0073e6;
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.addToDocumentButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.addToDocumentButton:hover {
  opacity: 1;
}

/* Remove .textEditBox and .textArea styles as they're now in Document.module.css */

.messageContent {
  width: 100%;
}

.messageContent pre {
  background-color: #f4f4f4;
  border-radius: 4px;
  padding: 10px;
  overflow-x: auto;
}

.messageContent code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
}

.messageContent p {
  margin: 0 0 10px 0;
}

.messageContent ul, .messageContent ol {
  margin: 0 0 10px 20px;
  padding: 0;
}

.aiMessage .messageContent {
  color: #333;
}

.userMessage .messageContent {
  color: white;
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
}

.loadingDots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingDots span {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #919191;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

.loadingDots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loadingDots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}

.typingIndicator {
  font-size: 12px;
  color: white;
  padding: 8px 12px;
  background-color: #0084ff;
  border-radius: 18px;
  margin-bottom: 10px;
  align-self: flex-end;
  max-width: 60%;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 768px) {
  .container {
    height: calc(100vh - 120px); /* Adjust based on header and bottom nav heights */
  }
}